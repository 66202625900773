import { createRouter, createWebHistory } from 'vue-router'
import { authGuardMiddleware } from '../middlewares/authGuard'
import { accountOnboardingGuardMiddleware } from '../middlewares/accountOnboardingGuard'

import {
  DASHBOARD_ROUTE_PATH,
  DEVICES_OVERVIEW_ROUTE_PATH,
  DEVICES_SINGLE_ROUTE_PATH,
  DEVICES_SINGLE_CONTAINERS_ROUTE_PATH,
  DEVICES_SINGLE_DEPLOYMENTS_ROUTE_PATH,
  DEVICES_SINGLE_LOGS_ROUTE_PATH,
  DEVICES_SINGLE_TERMINAL_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  ONBOARDING_PATH,
  SETTINGS_PATH,
  SETTINGS_PLAN_PATH,
  SETTINGS_CHANGE_PLAN_PATH,
  SETTINGS_CHANGE_PLAN_SUCCESS_PATH,
  SIGNUP_ROUTE_PATH,
  SETTINGS_SECURITY_PATH,
  SETTINGS_BILLING_PATH
} from '../constants/Routes'

/**
 * Route Definitions
 */
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: DASHBOARD_ROUTE_PATH,
      redirect: DEVICES_OVERVIEW_ROUTE_PATH
    },
    {
      path: DEVICES_OVERVIEW_ROUTE_PATH,
      beforeEnter: [authGuardMiddleware, accountOnboardingGuardMiddleware],
      children: [
        {
          path: '',
          component: () => import('../views/Devices/DevicesOverviewView.vue')
        },
        {
          path: DEVICES_SINGLE_ROUTE_PATH,
          component: () => import('../views/Devices/DeviceView.vue'),
          children: [
            {
              path: '',
              name: 'device-single',
              component: () => import('../views/Devices/DeviceGeneralView.vue')
            },
            {
              path: DEVICES_SINGLE_CONTAINERS_ROUTE_PATH,
              component: () => import('../views/Devices/DeviceContainersView.vue')
            },
            {
              path: DEVICES_SINGLE_DEPLOYMENTS_ROUTE_PATH,
              component: () => import('../views/Devices/DeviceDeploymentsView.vue')
            },
            {
              path: DEVICES_SINGLE_LOGS_ROUTE_PATH,
              component: () => import('../views/Devices/DeviceLogsView.vue')
            },
            {
              path: DEVICES_SINGLE_TERMINAL_ROUTE_PATH,
              component: () => import('../views/Devices/DeviceTerminalView.vue')
            }
          ]
        }
      ]
    },
    {
      path: SETTINGS_PATH,
      component: () => import('../views/Settings/SettingsView.vue'),
      beforeEnter: [authGuardMiddleware, accountOnboardingGuardMiddleware],
      children: [
        {
          path: SETTINGS_PATH,
          component: () => import('../views/Settings/ProfileView.vue')
        },
        {
          path: SETTINGS_PLAN_PATH,
          component: () => import('../views/Settings/Plan/PlanView.vue')
        },
        {
          path: SETTINGS_CHANGE_PLAN_PATH,
          component: () => import('../views/Settings/Plan/ChangePlanView.vue'),
          meta: {
            hideSidenav: true
          }
        },
        {
          path: SETTINGS_CHANGE_PLAN_SUCCESS_PATH,
          component: () => import('../views/Settings/Plan/ChangePlanSuccessView.vue'),
          meta: {
            hideSidenav: true
          }
        },
        {
          path: SETTINGS_SECURITY_PATH,
          component: () => import('../views/Settings/SecurityView.vue')
        },
        {
          path: SETTINGS_BILLING_PATH,
          component: () => import('../views/Settings/BillingView.vue')
        }
      ]
    },
    {
      name: 'onboarding',
      path: ONBOARDING_PATH,
      component: () => import('../views/OnboardingView.vue'),
      beforeEnter: [authGuardMiddleware],
      meta: {
        minimalNavbar: true
      }
    },
    {
      name: 'signup',
      path: SIGNUP_ROUTE_PATH,
      component: () => import('../views/SignupView.vue'),
      meta: {
        hideNavbar: true,
        requiresAuth: false
      }
    },
    {
      name: 'login',
      path: LOGIN_ROUTE_PATH,
      component: () => import('../views/LoginView.vue'),
      meta: {
        hideNavbar: true,
        requiresAuth: false
      }
    }
  ]
})

export default router
